import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Row, Button, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Select from "react-select";

const HSCodeRow = ({
  row,
  index,
  handleChange,
  retrieveHsCodeSearch,
  optionhsCode,
  optionDesc,
  handleDeleteRow,
  deleteIconhide,
}) => {
  const [selectedOptionhsCode, setSelectedOptionhsCode] = useState(null);
  const [selectedOptionDesc, setSelectedOptionDesc] = useState(null);

  // Check if dangerous goods condition is met (first two digits between 28 and 38)
  const isDangerousGood =
    row.hsCode &&
    parseInt(row.hsCode.substring(0, 2)) >= 28 &&
    parseInt(row.hsCode.substring(0, 2)) <= 38;

  useEffect(() => {
    if (isDangerousGood) {
      handleChange(index, "selectedOption", "Yes");
    }
  }, [isDangerousGood, index, handleChange]);

  console.log("🚀 ~ isDangerousGood:", isDangerousGood);
  const ophscode = {
    value: row.hsCode,
    label: row.hsCode,
    hsCode: row.hsCode,
    description: row.hsCode,
  };
  const opdes = {
    value: row.goodDescription,
    label: row.goodDescription,
    hsCode: row.goodDescription,
    description: row.goodDescription,
  };

  return (
    <div key={index}>
      <Row className="mb-3 mb-lg-2">
        <Col md={12}>
          <div className="scree_btn scree_btnShrink">
            <div className="w-100">
              <Form.Label style={{ color: "#222528" }}>
                {"HS Code Search (6 digits) *"}
              </Form.Label>
              <Select
                value={
                  row.hsCode && selectedOptionhsCode == null
                    ? ophscode
                    : selectedOptionhsCode
                }
                onChange={(e) => {
                  handleChange(index, "hsCode", e.value);
                  setSelectedOptionhsCode(e);

                  if (optionhsCode.length > 0) {
                    const ophsCode = optionhsCode.find(
                      (oe) => oe.hsCode === e.value
                    );
                    const opdesc = {
                      value: ophsCode.description,
                      label: ophsCode.description,
                      hsCode: ophsCode.hsCode,
                      description: ophsCode.description,
                    };
                    setSelectedOptionDesc(opdesc);
                  }
                }}
                options={optionhsCode}
                placeholder="If HS Code is unknown..."
                isSearchable
                onInputChange={(e) => {
                  retrieveHsCodeSearch("hsCode", e);
                }}
                onMenuOpen={() => {
                  retrieveHsCodeSearch("hsCode", "");
                }}
              />
            </div>

            <span className="orHeading mx-3"> OR </span>
            <div className="w-100">
              <Form.Label style={{ color: "#222528" }}>
                {"Goods Description *"}
              </Form.Label>
              <Select
                value={
                  row.goodDescription && selectedOptionhsCode == null
                    ? opdes
                    : selectedOptionDesc
                }
                onChange={(e) => {
                  handleChange(index, "goodDescription", e.value);
                  setSelectedOptionDesc(e);

                  if (optionDesc.length > 0) {
                    const opdesc = optionDesc.find(
                      (oe) => oe.description === e.value
                    );
                    const ophsCode = {
                      value: opdesc.hsCode,
                      label: opdesc.hsCode,
                      hsCode: opdesc.hsCode,
                      description: opdesc.description,
                    };
                    setSelectedOptionhsCode(ophsCode);
                  }
                }}
                options={optionDesc}
                placeholder="If good description is unknown..."
                isSearchable
                onInputChange={(e) => {
                  retrieveHsCodeSearch("description", e);
                }}
                onMenuOpen={() => {
                  retrieveHsCodeSearch("description", "");
                }}
              />
            </div>

            <div className="scree_btn scree_btn1 wWidth ms-4">
              <Form.Group className="mb-3">
                <Form.Label>Chemical Or Dangerous Good *</Form.Label>
                <select
                  className="form-control"
                  value={
                    isDangerousGood === true
                      ? "Yes"
                      : isDangerousGood === false
                      ? "No"
                      : "" // Default to empty string if undefined or null
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange(index, "selectedOption", value);
                  }}
                  disabled={isDangerousGood !== null} // Disable if dangerous good condition is met
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </Form.Group>
            </div>

            <div className="scree_btn scree_btn1 wWidth">
              <Form.Group className="mb-3">
                <Form.Label>Dual Use Export Controlled Goods *</Form.Label>
                <select
                  className="form-control"
                  value={row.DualSelectedOption || row.DualUseExport}
                  onChange={(e) =>
                    handleChange(index, "DualSelectedOption", e.target.value)
                  }
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </Form.Group>
            </div>

            {deleteIconhide !== true ? (
              <>
                {index !== 0 ? (
                  <Button
                    variant="primary"
                    size="md"
                    className="mr-1"
                    style={{ minWidth: 100 }}
                    onClick={() => handleDeleteRow(index)}
                  >
                    <Icon icon="pixelarticons:text-add" fontSize={18} />
                    Delete
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HSCodeRow;
